<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Grupos"
        p-read-link="groups.read.one"
        p-create-link="groups.create"
        p-name="groups"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwGroupRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id',
                width: '75px'
            }, {
                text: 'descrição',
                value: 'descricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ativo',
                value: 'ativo'
            }, {
                text: 'Ativo Desktop',
                value: 'ativo_des'
            }, {
                text: 'Ativo Web',
                value: 'ativo_web'
            }, {
                text: 'Ativo Celular',
                value: 'ativo_mob'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>